import React from 'react'
import { graphql } from 'gatsby'
import { Box, Flex, Heading } from '@chakra-ui/react'
import { truncate } from 'lodash'

import Layout from '../components/layout/Layout'
import Section from '../components/layout/Section'
import SEO from '../components/elements/SEO'
import Container from '../components/layout/Container'

function Event({ data }) {
  const page = data.prismicEvent.data
  return (
    <Layout>
      <SEO
        meta_title={page.title.text}
        meta_description={`${truncate(page.content.text, {
          length: 158,
          separator: ' ',
        })}`}
      />
      <Section bg="gray.50">
        <Container>
          <Flex flexWrap="wrap" justifyContent="center">
            <Box as="article" width={['full', 'full', 3 / 4, '50%']}>
              <Heading as="h1" mb={12}>
                {page.title.text}
              </Heading>
              <div
                dangerouslySetInnerHTML={{
                  __html: page.content.html,
                }}
              />
            </Box>
          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

export default Event

export const pageQuery = graphql`
  query EventPageSlug($uid: String!) {
    prismicEvent(uid: { eq: $uid }) {
      uid
      data {
        content {
          html
          text
        }
        event_date(formatString: "MMM Do, YYYY")
        title {
          text
        }
      }
    }
  }
`
